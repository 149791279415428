<template>
    <!-- start inner banner  -->
    <section class="inner-banner">

        <h1>My Company Panel</h1>

        <!-- start inner page content area  -->
        <section class="inner-page-content-area">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="inner-main-content-wrap">
                            <div class="inner-main-content">
                                <div class="privacy-policy">

                                    <div class="row">
                                        <div class="col-xs-12 col-md-4">
                                            <ul>
                                                <li>
                                                    <router-link :to="{ name: 'ViewMyCompany' }">Main Data</router-link>
                                                </li>
                                                <li><a>Invoicing</a></li>
                                                <li><a>Address</a></li>
                                            </ul>
                                        </div>
                                        <div class="col-xs-12 col-md-8">
                                            <router-view></router-view>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>

<script>
export default {
    name: "MyCompany"
}
</script>

<style scoped>

</style>